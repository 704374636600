<script>
import dialog from '@/extend/components/dialog/index'
import customTable from '@/views/components/customTable'
import { tableOption } from './const'

export default {
  extends: dialog,
  props: {
    isBatch: Boolean
  },
  data() {
    return {
      selectionData: []
    }
  },
  computed: {
    componentName() {
      return customTable
    },
    dialogAttrs() {
      return {
        title: `${ this.value.levelCategoryName || '' } 产品列表`
      }
    },
    bodyOptions() {
      return {
        data: this.value.productList,
        option: tableOption
      }
    },
    bodyListeners() {
      return {
        'selection-change': (selection) => {
          this.selectionData = selection
        }
      }
    },
    btnAttrs() {
      return {
        size: 'mini',
        type: 'text',
        text: `查看产品：${this.value.productCount}`
      }
    }
  },
  methods: {
    onsubmit() {
      return this.value.selectionData = this.selectionData
    },
    onclick() {
      this.selectionData = this.value.selectionData

      this.dialogVisible = true
    },
    onopened() {
      this.$refs.body.toggleSelection(this.selectionData)
    }
  }
}
</script>

<style lang="scss">

</style>
