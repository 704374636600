import { map } from 'lodash'
export const tableOption = {
  rowKey: 'id',
  selection: true,
  reserveSelection: true,
  menu: false,
  topPage: false,
  column: [
    {
      label: '原型名称',
      prop: 'chineseName'
    },
    {
      label: '款式',
      prop: 'levelItemId',
      formatter(row) {
        let styleNames =  map(row.customProductList, 'styleName').filter(Boolean)
        return styleNames.reduce((prev, next) => {
          return prev + `
            <span class="el-tag el-tag--primary el-tag--light" style="margin-right: 5px;">${next}</span>
          `
        }, '')
      }
    }
  ]
}